import {
    GET_PROFILE,
    SET_PROFILE,
    UPDATE_PROFILE,
    INITIAL_STATES,
    SET_GROUPS_MENU,
} from "./actionTypes"

export const getProfile = () => {
    return {
      type: GET_PROFILE
    }
}

export const setProfile = (payload) => {
    return {
      type: SET_PROFILE,
      payload: payload
    }
}

export const getProfileSuccess = (payload) => {
    return {
      type: GET_PROFILE + `_SUCCESS`,
      payload: payload
    }
}

export const getProfileFailed = (error) => {
    return {
        type: GET_PROFILE + `_FAILED`,
        error: error
    }
}

export const updateProfile = (data) => {
    return {
      type: UPDATE_PROFILE,
      payload: data
    }
}

export const updateProfileSuccess = (data) => {
    return {
      type: UPDATE_PROFILE + `_SUCCESS`,
      payload: data
    }
}

export const updateProfileFailed = (data) => {
    return {
      type: UPDATE_PROFILE + `_FAILED`,
      payload: data
    }
}

export const setGroup = (data) => {
  return {
      type: 'SET_GROUPS',
      payload: data
  }
}

export const setGroupMenu = (data) => {
  return {
      type: SET_GROUPS_MENU,
      payload: data
  }
}

export const initial = () => {
    return {
        type: INITIAL_STATES,
    }
}