import React, { useEffect, useRef, useState } from 'react'
import '../../assets/css/EditProfile.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { createApiClient } from '@oneblinktech-org/helios-base';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Spin, Modal } from 'antd'
import { dateFormat } from '../../helpers/dateFormat';
import ResetPassword from './resetPassword';
import './helpers/sticky'
import { getProfile } from '../../store/profile/actions';

function EditProfile(props) {
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const history = useHistory();

    const [public_fields, setPublicFields] = useState(props.user?.user_setting?.public_fields)
    const [loader, setLoader] = useState(false)
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const [profileImage, setProfileImage] = useState(props.user?.full_profile_image_url)
    const [refresh, setRefresh] = useState(true)

    const apiUrl = process.env.REACT_APP_API_URL;
    const { post } = createApiClient({ apiUrl });
    const token = localStorage.getItem('h-access_token')

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, dirtyFields, isSubmitting },
        trigger,
        reset,
        watch,
        getValues,
        setValue
    } = useForm({
        defaultValues: {
            full_profile_image_url: props.user?.full_profile_image_url || '',
            firstname: props.user?.firstname || '',
            lastname: props.user?.lastname || '',
            email: props.user?.email || '',
            business_name: props.user?.user_setting?.business_name || '',
            address: props.user?.address || '',
            address2: props.user?.address2 || '',
            city: props.user?.city || '',
            state: props.user?.state || '',
            zipcode: props.user?.zipcode || '',
            main_phone: props.user?.main_phone || '',
            mobile_phone: props.user?.user_setting?.mobile_phone || '',
            business_description: props.user?.user_setting?.business_description || '',
            profession: props.user?.user_setting?.profession || '',
            specialty: props.user?.user_setting?.specialty || '',
            website: props.user?.user_setting?.website || '',
            linkedin: props.user?.user_setting?.linkedin || '',
            facebook: props.user?.user_setting?.facebook || '',
            twitter: props.user?.user_setting?.twitter || '',
            calendly: props.user?.user_setting?.calendly || '',
            my_why: props.user?.user_setting?.my_why || '',
            my_why_description: props.user?.user_setting?.my_why_description || '',
            public_fields: props.user?.user_setting?.public_fields || []
        }
    });

    useEffect(() => {
        setPublicFields(props.user?.user_setting?.public_fields)
        setProfileImage(props.user?.full_profile_image_url)

        reset({
            full_profile_image_url: props.user?.full_profile_image_url || '',
            firstname: props.user?.firstname || '',
            lastname: props.user?.lastname || '',
            email: props.user?.email || '',
            business_name: props.user?.user_setting?.business_name || '',
            address: props.user?.address || '',
            address2: props.user?.address2 || '',
            city: props.user?.city || '',
            state: props.user?.state || '',
            zipcode: props.user?.zipcode || '',
            main_phone: props.user?.main_phone || '',
            mobile_phone: props.user?.user_setting?.mobile_phone || '',
            business_description: props.user?.user_setting?.business_description || '',
            profession: props.user?.user_setting?.profession || '',
            specialty: props.user?.user_setting?.specialty || '',
            website: props.user?.user_setting?.website || '',
            linkedin: props.user?.user_setting?.linkedin || '',
            facebook: props.user?.user_setting?.facebook || '',
            twitter: props.user?.user_setting?.twitter || '',
            calendly: props.user?.user_setting?.calendly || '',
            my_why: props.user?.user_setting?.my_why || '',
            my_why_description: props.user?.user_setting?.my_why_description || '',
            public_fields: props.user?.user_setting?.public_fields || []
        })
    }, [props.user]);

    const watchAllFields = watch()

    async function changePublicSettings(key) {
        let p_fields = getValues("public_fields")

        if (p_fields?.includes(key)) {
            const temp_arr = p_fields?.filter(i => i !== key)
            setValue('public_fields', temp_arr)
        } else {
            var temp = getValues("public_fields")
            temp.push(key)
            setValue('public_fields', temp)
        }
        await setRefresh(!refresh)
    }

    const handleClick = () => {
        // open file input box on click of another element
        inputRef.current.click();
    };

    const changePassoword = () => {

    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        setProfileImage(URL.createObjectURL(fileObj));

        // reset file input
        event.target.value = null;
    };

    async function onSaveChanges(data) {
        let form = new FormData()
        if (data.full_profile_image_url.length == 1) {
            form.append('full_profile_image_url', data.full_profile_image_url[0], (data.full_profile_image_url[0]).name)
        }

        Object.keys(data).forEach(key => {
            if (key == 'email') {
                if (data.email == props.user?.email) {
                    return;
                }
            }

            if (key !== 'full_profile_image_url') {
                form.append(key, data[key]);
            }

        });

        form.append('public_fields', data.public_fields)

        var hasError = false;
        const isValid = await trigger();
        if (!isValid) {
            hasError = true;
            console.log(errors);
            return;
        }
        if (!hasError) {

            if (data.email === props.user?.email) {
                const { email, ...paramsWithoutEmail } = data;
                data = paramsWithoutEmail;
            }

            try {
                const result = await post('/ithaca/users/updateMyProfile', form, {
                    "Authorization": `Bearer ` + token
                })

                if (result.status != 200) {
                    const responseData = await result.json();
                    if (responseData && responseData.errors) {
                        NotificationManager.error('An error occurred', 'Error', 7000);
                    } else {
                        NotificationManager.error('An error occurred', 'Error', 7000);
                    }
                }

                if (result.data) {
                    reset({
                        full_profile_image_url: result.data?.full_profile_image_url || '',
                        firstname: result.data?.firstname || '',
                        lastname: result.data?.lastname || '',
                        email: result.data?.email || '',
                        business_name: result.data?.user_setting?.business_name || '',
                        address: result.data?.address || '',
                        address2: result.data?.address2 || '',
                        city: result.data?.city || '',
                        state: result.data?.state || '',
                        zipcode: result.data?.zipcode || '',
                        main_phone: result.data?.main_phone || '',
                        mobile_phone: result.data?.user_setting?.mobile_phone || '',
                        business_description: result.data?.user_setting?.business_description || '',
                        profession: result.data?.user_setting?.profession || '',
                        specialty: result.data?.user_setting?.specialty || '',
                        website: result.data?.user_setting?.website || '',
                        linkedin: result.data?.user_setting?.linkedin || '',
                        facebook: result.data?.user_setting?.facebook || '',
                        twitter: result.data?.user_setting?.twitter || '',
                        calendly: result.data?.user_setting?.calendly || '',
                        my_why: result.user?.user_setting?.my_why || '',
                        my_why_description: result.user?.user_setting?.my_why_description || '',
                        public_fields: result.data?.user_setting?.public_fields || []
                    })
                    props.getProfile()
                    history.push({
                        pathname: '/profile',
                        state: { isEdit: 'success' }
                    });
                }

            } catch (error) {
                let errs = error?.response?.data?.errors;
                let msg = error?.response?.data?.message;

                if (errs) {
                    Object.keys(errs).forEach(field => {
                        const messages = errs[field];
                        messages.forEach(message => {
                            NotificationManager.error(message, msg, 7000);
                        });
                    });
                } else {
                    NotificationManager.error('An error occurred', 'Error', 7000);
                }
            }
        }

    }

    return (
        <>
            <Spin spinning={loader} size='large'>
                <div className="profile-info">
                    <div className="profile-inner-info">
                        <div className="component-197-info">

                            <div className="component-197-profile" id='frameParentInfo'>
                                <div className="frame-parent-info edit-profilr md-w-full">
                                    <b className="profile-overview">Edit Profile</b>

                                    <div className="cta-parent">
                                        {isDirty && (
                                            <div className="cta">You have unsaved changes</div>
                                        )}
                                        <div className="cancel" onClick={() => history.push("/Profile")} >Cancel</div>
                                        <button className="add-new-user-hover-edit edit-profile-btn" type="submit"
                                            disabled={isSubmitting}
                                            onClick={() => handleSubmit(onSaveChanges)()}>
                                            {isSubmitting ?
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : 'Save Changes'
                                            }</button>
                                    </div>
                                </div>
                            </div>

                            <div className="info-content">
                                {/* <div > */}
                                {/* <div className="frame-child" /> */}
                                <form onSubmit={handleSubmit(onSaveChanges)} className='frame-group-info'>
                                    <div className="col">
                                        <div className="addnewuser-btn-addnewuser-parent">
                                            <div className="addnewuser-btn-addnewuser">
                                                <div className="component-749-parent">
                                                    <div className="component-749">
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id='file_uploader_edit'
                                                            type="file"
                                                            accept='image/.jpeg, .png, .jpg, .svg'
                                                            name="full_profile_image_url"
                                                            {...register('full_profile_image_url')}
                                                        />
                                                        {
                                                            getValues('full_profile_image_url') != "" && getValues('full_profile_image_url') != null && getValues('full_profile_image_url').length > 1 ?
                                                                <label className="wrapper-rectangle-23 profile-avtar" htmlFor="file_uploader_edit">
                                                                    <img
                                                                        className="wrapper-rectangle-23-child"
                                                                        loading="eager"
                                                                        alt=""
                                                                        src={getValues('full_profile_image_url')}
                                                                    />
                                                                    <span className='change-image'>CHANGE IMAGE</span>
                                                                </label>
                                                                : (
                                                                    <label className="wrapper-rectangle-23 profile-avtar" htmlFor="file_uploader_edit">
                                                                        <img
                                                                            className="wrapper-rectangle-23-child"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={getValues('full_profile_image_url').length > 0 ? URL.createObjectURL(getValues('full_profile_image_url')[0]) : require("../../assets/images/user-avatar.png").default}
                                                                        />
                                                                        <span className='change-image'>CHANGE IMAGE</span>
                                                                    </label>
                                                                )

                                                        }
                                                        {errors.full_profile_image_url && <small className='text-danger error-text'>This field is required</small>}
                                                    </div>
                                                    <div className="frame-component-grid">
                                                        <div className="frame-line-title-component">
                                                            <div className="title">Current Photo</div>
                                                            <div onClick={() => changePublicSettings("full_profile_image_url")} key={refresh} >
                                                                {getValues("public_fields")?.includes("full_profile_image_url") ?
                                                                    <img
                                                                        className="component-750-icon"
                                                                        loading="eager"
                                                                        alt=""
                                                                        src={require("../../assets/images/eye-blue.svg").default}
                                                                    />
                                                                    :
                                                                    <img
                                                                        className="component-750-icon-disable"
                                                                        loading="eager"
                                                                        alt=""
                                                                        src={require("../../assets/images/eye-gray.svg").default}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="frame-frame1">
                                                            <b className="title1">Recommended size:</b>
                                                            <div className="frame-n-a-m-e-frame">
                                                                <div className="title2">minimum: 100x100</div>
                                                                <div className="title3">maximum: 3000x3000</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frame-component-n-a-m-e">
                                                    <div className="title4">
                                                        <span>{`Last updated: `}</span>
                                                        <span className="jan-3-2024">{dateFormat(props.user?.updated_at, 'USER_LOGIN_DATE_FORMAT')}</span>
                                                    </div>
                                                    <div className="btnadd-new-userbtnadd-new-u">
                                                        <div className="frame-frame-frame">
                                                            <button type='button' onClick={() => setResetModalOpen(true)} className="add-new-user-hover-edit">
                                                                <div className="cta1-edit">Change Password</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="component-f-r-a-m-e">
                                                <div className="frame-l-i-n-e-title-component group-inps">
                                                    <div className="component-693 group-inp">
                                                        <div className="frame-n-a-m-e label">
                                                            <b className="name">First Name *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />

                                                                <input
                                                                    className="title5"
                                                                    placeholder="First Name"
                                                                    type="text"
                                                                    name={'firstname'}
                                                                    {...register("firstname", {
                                                                        required: { value: true, message: 'First Name is required' },
                                                                        maxLength: { value: 50, message: 'The Firstname length is between 2 and 50 characters' },
                                                                        minLength: { value: 2, message: 'The Firstname length is between 2 and 50 characters' }
                                                                    })}
                                                                    maxLength={50}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors.firstname && <p className='inp-error'>{errors?.firstname?.message}</p>}
                                                                    <div className="name1">{`${getValues('firstname')?.length}/${50}`}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-697 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Last Name *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Last Name"
                                                                    type="text"
                                                                    name={'lastname'}
                                                                    {...register("lastname", {
                                                                        required: { value: true, message: 'Lastname is required' },
                                                                        maxLength: { value: 50, message: 'The Lastname length is between 2 and 50 characters' },
                                                                        minLength: { value: 2, message: 'The Lastname length is between 2 and 50 characters' }
                                                                    })}
                                                                    maxLength={50}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors.lastname && <p className='inp-error'>{errors?.lastname?.message}</p>}
                                                                    <div className="name1">{`${getValues('lastname')?.length}/${50}`}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-700 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Email *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Email"
                                                                    type="text"
                                                                    name={'email'}
                                                                    {...register("email", {
                                                                        required: { value: true, message: 'Email is required' },
                                                                        maxLength: { value: 100, message: 'The email length is between 2 and 100 characters' },
                                                                        minLength: { value: 2, message: 'The email length is between 2 and 100 characters' },
                                                                        pattern: { value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, message: 'Invalid email' }
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.email && <p className='inp-error'>{errors?.email?.message}</p>}
                                                                    <div className="name1">{`${getValues('email')?.length}/${100}`}</div>
                                                                </div>
                                                                <div onClick={() => changePublicSettings("email")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("email") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="frame-frame2">
                                                <div className="frame-f-r-a-m-e-component group-inps">
                                                    <div className="component-710 group-inp">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">Business Name *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Business Name"
                                                                    type="text"
                                                                    name={'business_name'}
                                                                    {...register("business_name", {
                                                                        required: { value: true, message: 'Business name is required' },
                                                                        maxLength: { value: 100, message: 'The business name length is between 3 and 100 characters' },
                                                                        minLength: { value: 3, message: 'The business name length is between 3 and 100 characters' }
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.business_name && <p className='inp-error'>{errors?.business_name?.message}</p>}
                                                                    <div className="name1">{`${getValues('business_name')?.length}/${100}`}</div>
                                                                </div>
                                                                {/* <div key={refresh} >
                                                                    <img
                                                                        style={{ cursor: 'auto' }}
                                                                        className="component-690-icon2"
                                                                        loading="eager"
                                                                        alt=""
                                                                        src={require("../../assets/images/eye-blue.svg").default}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-711 group-inp">
                                                        <div className="name-frame label">
                                                            <b className="name">Address Line 1 *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Street Address 1"
                                                                    type="text"
                                                                    name={'address'}
                                                                    {...register("address", {
                                                                        required: { value: true, message: 'Address is required' },
                                                                        maxLength: { value: 100, message: 'The address length is between 3 and 100 characters' },
                                                                        minLength: { value: 3, message: 'The address length is between 3 and 100 characters' }
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.address && <p className='inp-error'>{errors?.address?.message}</p>}
                                                                    <div className="name1">{`${getValues('address')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("address")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("address") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-711 group-inp">
                                                        <div className="name-frame label">
                                                            <b className="name">Address Line 2</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Street Address 2"
                                                                    type="text"
                                                                    name={'address2'}
                                                                    {...register("address2", {
                                                                        maxLength: { value: 100, message: 'The address2 length is between 3 and 100 characters' },
                                                                        minLength: { value: 3, message: 'The address2 length is between 3 and 100 characters' }
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.address2 && <p className='inp-error'>{errors?.address2?.message}</p>}
                                                                    <div className="name1">{`${getValues('address2')?.length ? getValues('address2')?.length : 0}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("address2")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("address2") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frame-f-r-a-m-e-component group-inps">
                                                    <div className="component-710 group-inp">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">City *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="City"
                                                                    type="text"
                                                                    name={'city'}
                                                                    {...register("city", {
                                                                        required: { value: true, message: 'City is required' },
                                                                        maxLength: { value: 50, message: 'The city length is between 2 and 50 characters' },
                                                                        minLength: { value: 2, message: 'The city length is between 2 and 50 characters' }
                                                                    })}
                                                                    maxLength={50}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.city && <p className='inp-error'>{errors?.city?.message}</p>}
                                                                    <div className="name1">{`${getValues('city')?.length}/${50}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("city")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("city") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-710 group-inp inline_block">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">State *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="State"
                                                                    type="text"
                                                                    name='state'
                                                                    {...register("state", {
                                                                        required: { value: true, message: 'State is required' },
                                                                        maxLength: { value: 50, message: 'Ensure the state length is between 2 and 50 characters.' },
                                                                        minLength: { value: 2, message: 'Ensure the state length is between 2 and 50 characters.' }
                                                                    })}
                                                                    maxLength={50}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.state && <p className='inp-error'>{errors?.state?.message}</p>}
                                                                    <div className="name1">{`${getValues('state')?.length}/${50}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("state")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("state") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-710 group-inp inline_block">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">Zip *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Zip"
                                                                    type="text"
                                                                    name='zipcode'
                                                                    {...register("zipcode", {
                                                                        required: { value: true, message: 'Zip is required' },
                                                                        maxLength: { value: 10, message: 'The zipcode length is between 5 and 10 characters' },
                                                                        minLength: { value: 5, message: 'The zipcode length is between 5 and 10 characters' }
                                                                    })}
                                                                    maxLength={10}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.zipcode && <p className='inp-error'>{errors?.zipcode?.message}</p>}
                                                                    <div className="name1">{`${getValues('zipcode')?.length}/${10}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("zipcode")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("zipcode") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-710 group-inp">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">PHONE</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Phone"
                                                                    type="text"
                                                                    minLength={7}
                                                                    maxLength={20}
                                                                    name='main_phone'
                                                                    {...register("main_phone", {
                                                                        maxLength: { value: 20, message: 'The phone length is between 7 and 20 characters' },
                                                                        minLength: { value: 7, message: 'The phone length is between 7 and 20 characters' }
                                                                    })}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.main_phone && <p className='inp-error'>{errors?.main_phone?.message}</p>}
                                                                    <div className="name1">{`${getValues('main_phone')?.length}/${20}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("main_phone")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("main_phone") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-710 group-inp">
                                                        <div className="frame-n-a-m-e-component label">
                                                            <b className="name">MOBILE</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-parent1-edit">
                                                            <div className="line-parent1">
                                                                <div className="frame-child1" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Mobile"
                                                                    type="text"
                                                                    name='mobile_phone'
                                                                    {...register("mobile_phone", {
                                                                        required: { value: false },
                                                                        maxLength: { value: 20, message: 'Ensure the phone length is between 7 and 20 characters.' },
                                                                        minLength: { value: 7, message: 'Ensure the phone length is between 7 and 20 characters.' }
                                                                    })}
                                                                    maxLength={20}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.mobile_phone && <p className='inp-error'>{errors?.mobile_phone?.message}</p>}
                                                                    <div className="name1">{`${getValues('mobile_phone')?.length ? getValues('mobile_phone')?.length : 0}/${20}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("mobile_phone")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("mobile_phone") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="frame-master">
                                                <div className="frame-child-a group-inps">
                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Profession *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Profession"
                                                                    type="text"
                                                                    name='profession'
                                                                    {...register("profession", {
                                                                        required: { value: true, message: 'Profession is required' },
                                                                        maxLength: { value: 100, message: ' The Profession length is between 3 and 100 characters.' },
                                                                        minLength: { value: 3, message: ' The Profession length is between 3 and 100 characters.' }
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.profession && <p className='inp-error'>{errors?.profession?.message}</p>}
                                                                    <div className="name1">{`${getValues('profession')?.length}/${100}`}</div>
                                                                </div>

                                                                {/* <div onClick={() => changePublicSettings("profession")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("profession") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-726 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Business Description</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Business Description"
                                                                    type="text"
                                                                    name='business_description'
                                                                    {...register("business_description", {
                                                                        maxLength: { value: 500, message: 'The description length is between 10 and 500 characters.' },
                                                                        minLength: { value: 10, message: 'The description length is between 10 and 500 characters.' }
                                                                    })}
                                                                    maxLength={500}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.business_description && <p className='inp-error'>{errors?.business_description?.message}</p>}
                                                                    <div className="name1">{`${getValues('business_description')?.length}/${500}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("business_description")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("business_description") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Speciality *</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Specialty"
                                                                    type="text"
                                                                    name='specialty'
                                                                    {...register("specialty", {
                                                                        required: { value: true, message: 'Specialty is required' },
                                                                        maxLength: { value: 200, message: 'The specialty length is between 5 and 200 characters' },
                                                                        minLength: { value: 5, message: 'The specialty length is between 5 and 200 characters' }
                                                                    })}
                                                                    maxLength={200}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.specialty && <p className='inp-error'>{errors?.specialty?.message}</p>}
                                                                    <div className="name1">{`${getValues('specialty')?.length}/${200}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("specialty")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("specialty") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Website</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Website"
                                                                    type="text"
                                                                    name='website'
                                                                    {...register("website", {
                                                                        pattern: { value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, message: 'Invalid Website URL' }
                                                                    })}
                                                                    maxLength={100}
                                                                    onChange={(e) => setValue('website', e.target.value.replace(/^\s/, ''))}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.website && <p className='inp-error'>{errors?.website?.message}</p>}
                                                                    <div className="name1">{`${getValues('website')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("website")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("website") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">LinkedIn</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="LinkedIn"
                                                                    type="text"
                                                                    name='linkedin'
                                                                    {...register("linkedin", {
                                                                        pattern: { value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, message: 'Invalid LinkedIn URL' }
                                                                    })}
                                                                    maxLength={100}
                                                                    onChange={(e) => setValue('linkedin', e.target.value.replace(/^\s/, ''))}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.linkedin && (
                                                                        <p className='inp-error'>{errors?.linkedin?.message}</p>
                                                                    )}
                                                                    <div className="name1">{`${getValues('linkedin')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("linkedin")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("linkedin") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Facebook</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Facebook"
                                                                    type="text"
                                                                    name='facebook'
                                                                    {...register("facebook", {
                                                                        pattern: { value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, message: 'Invalid Facebook URL' }
                                                                    })}
                                                                    maxLength={100}
                                                                    onChange={(e) => setValue('facebook', e.target.value.replace(/^\s/, ''))}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.facebook && <p className='inp-error'>{errors?.facebook?.message}</p>}
                                                                    <div className="name1">{`${getValues('facebook')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("facebook")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("facebook") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="component-746 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">X</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="X"
                                                                    type="text"
                                                                    name='twitter'
                                                                    {...register("twitter", {
                                                                        pattern: { value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, message: 'Invalid Twitter URL' }
                                                                    })}
                                                                    maxLength={100}
                                                                    onChange={(e) => setValue('twitter', e.target.value.replace(/^\s/, ''))}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.twitter && <p className='inp-error'>{errors?.twitter?.message}</p>}
                                                                    <div className="name1">{`${getValues('twitter')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("twitter")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("twitter") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">Calendly</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="Calendly"
                                                                    type="text"
                                                                    name='calendly'
                                                                    {...register("calendly", {
                                                                        pattern: { value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, message: 'Invalid Calendly URL' }
                                                                    })}
                                                                    maxLength={100}
                                                                    onChange={(e) => setValue('calendly', e.target.value.replace(/^\s/, ''))}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.calendly && <p className='inp-error'>{errors?.calendly?.message}</p>}
                                                                    <div className="name1">{`${getValues('calendly')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("calendly")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("calendly") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >

                                            <div className="frame-master">
                                                <div className="frame-child-a group-inps">

                                                    <div className="component-732 group-inp">
                                                        <div className="name-wrapper label">
                                                            <b className="name">My Why (2-word title)</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e">
                                                                <div className="line-title-component" />
                                                                <input
                                                                    className="title5"
                                                                    placeholder="My Why Title"
                                                                    type="text"
                                                                    name='my_why'
                                                                    {...register("my_why", {
                                                                        maxLength: { value: 100, message: 'The My Why length is between 5 and 100 characters' },
                                                                        minLength: { value: 5, message: 'The My Why length is between 5 and 100 characters' },
                                                                    })}
                                                                    maxLength={100}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.my_why && <p className='inp-error'>{errors?.my_why?.message}</p>}
                                                                    <div className="name1">{`${getValues('my_why')?.length}/${100}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("my_why")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("my_why") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="component-732 group-inp column-two">
                                                        <div className="name-wrapper label">
                                                            <b className="name">My Why Description</b>
                                                            <img
                                                                className="vector-icon"
                                                                alt=""
                                                                src={require("../../assets/images/edit.svg").default}
                                                            />
                                                        </div>
                                                        <div className="frame-frame-l-i-n-e-title-comp">
                                                            <div className="frame-f-r-a-m-e textarea-height">
                                                                <textarea
                                                                    className="title5 line-textarea"
                                                                    placeholder="My Why Description"
                                                                    rows="5"
                                                                    name='my_why_description'
                                                                    {...register("my_why_description", {
                                                                        maxLength: { value: 500, message: 'The My Why Description length is between 5 and 500 characters' },
                                                                        minLength: { value: 5, message: 'The My Why Description length is between 5 and 500 characters' },
                                                                    })}
                                                                    maxLength={500}
                                                                />
                                                                <div className='input-footer'>
                                                                    {errors?.my_why_description && <p className='inp-error'>{errors?.my_why_description?.message}</p>}
                                                                    <div className="name1">{`${getValues('my_why_description')?.length}/${500}`}</div>
                                                                </div>

                                                                <div onClick={() => changePublicSettings("my_why_description")} key={refresh} >
                                                                    {getValues("public_fields")?.includes("my_why_description") ?
                                                                        <img
                                                                            className="component-690-icon2"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-blue.svg").default}
                                                                        />
                                                                        :
                                                                        <img
                                                                            className="eye-disable"
                                                                            loading="eager"
                                                                            alt=""
                                                                            src={require("../../assets/images/eye-gray.svg").default}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </form >
                                {/* </div> */}
                            </div >
                        </div >
                    </div >
                </div >
                <Modal className='reset_modal' width={750} centered onCancel={() => setResetModalOpen(false)} visible={resetModalOpen} title={null} footer={null}>
                    <ResetPassword onCancel={() => setResetModalOpen(false)} />
                </Modal>
            </Spin >
        </>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.profile.user.data,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(getProfile()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)