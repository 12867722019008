import { createApiClient } from '@oneblinktech-org/helios-base';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, remove, put } = createApiClient({ apiUrl });
const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' }

export async function getProfile() {
    const token = localStorage.getItem('h-access_token')
    try {
        const result = await get('ithaca/users/mine', {
            "Authorization": `Bearer ` + token
        })
        return result
    } catch (e) {
        throw e
    }
}

export async function updateProfile(payload) {
    const token = localStorage.getItem('h-access_token')
    
    try {
        const result = await post('/users/profile', payload.form, {
            "Content-Type": 'multipart/form-data',
            "Authorization": `Bearer ` + token
        })
        return result
    } catch (e) {
        throw e
    }
}