import React from 'react'
import { ReactComponent as Twitter } from "../../assets/images/x-social.svg"
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg"
import { ReactComponent as LinkedIn } from "../../assets/images/linkedin.svg"
import { ReactComponent as Calendly } from "../../assets/images/Brand_mark.svg"

function SocialIcons({ 
    linkedin = '', 
    facebook = '', 
    twitter = '', 
    calendly = '', 
    fill = 'var(--trustegrity)', 
    publicFields = [], 
    parentClassName = '' 
}) {

    const pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?/i;

    const facebookLink = facebook?.trim() && /^(https?:\/\/)?(www\.)?facebook.com\/?/i.test(facebook)
        ? (pattern.test(facebook) ? facebook : 'https://' + facebook)
        : null;

    const linkedinLink = linkedin?.trim() && /^(https?:\/\/)?(www\.)?linkedin.com\/?/i.test(linkedin)
        ? (pattern.test(linkedin) ? linkedin : 'https://' + linkedin)
        : null;

    const twitterLink = twitter?.trim() && /^(https?:\/\/)?(www\.)?(twitter|x).com\/?/i.test(twitter)
        ? (pattern.test(twitter) ? twitter : 'https://' + twitter)
        : null;

    const calendlyLink = calendly?.trim() && /^(https?:\/\/)?(www\.)?calendly.com\/?/i.test(calendly)
        ? (pattern.test(calendly) ? calendly : 'https://' + calendly)
        : null;

    const hasAccessToken = localStorage.getItem('h-access_token');
    const shouldShowLinkFacebook = hasAccessToken || (!hasAccessToken && publicFields.includes('facebook'));
    const shouldShowLinkLinkedin = hasAccessToken || (!hasAccessToken && publicFields.includes('linkedin'));
    const shouldShowLinkTwitter = hasAccessToken || (!hasAccessToken && publicFields.includes('twitter'));
    const shouldShowLinkCalendy = hasAccessToken || (!hasAccessToken && publicFields.includes('calendly'));

    return (
        <>
            {linkedinLink && shouldShowLinkLinkedin ? (
                <a href={linkedinLink} target="_blank" rel="noreferrer">
                    <LinkedIn className={parentClassName} fill={fill} />
                </a>
            ) : null}
            {facebookLink && shouldShowLinkFacebook ? (
                <a href={facebookLink} target="_blank" rel="noreferrer">
                    <Facebook className={parentClassName} fill={fill} />
                </a>
            ) : null}
            {twitterLink && shouldShowLinkTwitter ? (
                <a href={twitterLink} target="_blank" rel="noreferrer">
                    <Twitter className={parentClassName} fill={fill} />
                </a>
            ) : null}
            {calendlyLink && shouldShowLinkCalendy ? (
                <a href={calendlyLink} target="_blank" rel="noreferrer">
                    <Calendly className={parentClassName} fill={fill} />
                </a>
            ) : null}
        </>
    )
}

export default SocialIcons