import React, { useEffect, useState, useRef, useCallback } from 'react'
import { GoogleMap, LoadScript, Marker, useLoadScript, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import { debounce, throttle } from 'lodash';
import SearchForm from './SearchForm'
import { defaultLocation } from '../../helpers/globals.js'
import LeftColumn from './LeftColumn';
import { Link } from 'react-router-dom';

const libraries = ['places'];

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 53px)'
};

const distanceToZoomMap = [
    { maxDistance: 5, zoomLevel: 14 },
    { maxDistance: 10, zoomLevel: 13 },
    { maxDistance: 20, zoomLevel: 12 },
    { maxDistance: 50, zoomLevel: 11 },
    { maxDistance: 100, zoomLevel: 10 },
    { maxDistance: 200, zoomLevel: 9 },
    { maxDistance: 500, zoomLevel: 8 },
    { maxDistance: 800, zoomLevel: 7 },
    { maxDistance: 1000, zoomLevel: 6 }
]; 

function GroupMap() {

    const [searchResults, setSearchResults] = useState([]);
    const [visible, setVisible] = useState(true)
    const [zoom, setZoom] = useState(parseInt(localStorage.getItem('zoom')) || 5);
    const [bounds, setBounds] = useState(JSON.parse(localStorage.getItem('bounds')) || null);
    const [isSearchBarSmall, setIsSearchBarSmall] = useState(zoom >= 6);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const isLeftColumnVisible = zoom >= 1;
    const [tooltipmapRef, setTooltipmapRef] = useState();
    const [loading, setLoading] = useState(true)
    const [center, setCenter] = useState(defaultLocation)
    const [currentLocation, setCurrentLocation] = useState(JSON.parse(localStorage.getItem('viewerLocation')) || null)
    const [isReleaseMouseEvent, setReleaseMouseEvent] = useState(true)
    const [movementCounter, setMovementCounter] = useState(0.5)

    const mapRef = useRef();
    const searchRef = useRef(false);
    const mapLoaded = useRef(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setMovementCounter((prev) => prev + 0.5)
        }, 500)
        return () => {
            clearInterval(interval)
        }
    }, [])
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            localStorage.setItem("userLocation", JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude }));
            if (localStorage.getItem('viewerLocation') == null) {
                setCurrentLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
              localStorage.setItem("viewerLocation", JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude }));
            }
        });
    }, []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries,
      });

    const onZoomChanged = useCallback((map) => {
        if (!searchRef.current) {
            localStorage.setItem("zoom", map.getZoom())
            setZoom(map.getZoom())
            if (parseInt(map.getZoom()) < 6) {
                setIsSearchBarSmall(false)
            } else {
                setIsSearchBarSmall(true)
            }
        }
    }, []);

    const onBoundsChanged = useCallback(
        throttle(() => {
          if (mapLoaded.current) {
            const bounds = mapRef.current.getBounds();
            const center = bounds.getCenter();

            if (bounds) {
              const ne = bounds.getNorthEast();
              const sw = bounds.getSouthWest();
              setBounds({
                swLat: sw.lat(),
                swLng: sw.lng(),
                neLat: ne.lat(),
                neLng: ne.lng(),
              });
              localStorage.setItem("bounds", JSON.stringify({ swLat: sw.lat(), swLng: sw.lng(), neLat: ne.lat(), neLng: ne.lng() }));
              localStorage.setItem("viewerLocation", JSON.stringify({ lat: center.lat(), lng: center.lng() }));
            }
          }
        }, 2000),
        []
    );      

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setTooltipmapRef(map);
        map.addListener("zoom_changed", () => {

            if(!searchRef.current) {
                onZoomChanged(map)
            }
        });
        
        map.addListener("bounds_changed", () => {
            if(!searchRef.current) {
                onBoundsChanged(map)
                mapLoaded.current = true;
            }
        });
    }, [onZoomChanged, onBoundsChanged]);
  
    const handleSearch = (result, distance, formSearch) => {
        setReleaseMouseEvent(false)
        if(result.bounds) {
            setReleaseMouseEvent(true)
            const tBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(result.bounds.swLat, result.bounds.swLng),
                new window.google.maps.LatLng(result.bounds.neLat, result.bounds.neLng)
                );
            mapRef.current.fitBounds(tBounds);
            setBounds(result.bounds)
        }

        if(formSearch) {
            const zoom = getZoomForDistance(distance);
            setZoom(zoom);
        }
            
        setSearchResults(result.groups)
    };

    const handleMarkerClick = (id, result) => {
        // let lat = parseFloat(result.latitude);
        // let lng = parseFloat(result.longitude);

        // tooltipmapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, result });
        setIsOpen(true);
    };

    const getZoomForDistance = (distance) => {
        for (let i = 0; i < distanceToZoomMap.length; i++) {
            if (distance <= distanceToZoomMap[i].maxDistance) {
                return distanceToZoomMap[i].zoomLevel;
            }
        }
        return 7;
    };

    if (!isLoaded) return (
        <div className='d-flex justify-content-center full-height align-items-center'>Loading Maps</div>
    );

    const mapOptions = {
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT
      },
      gestureHandling: "greedy",
    };

    const milesToZoom = (miles) => {
        const equatorLength = 40075004; // in meters
        const zoomLevel0Scale = equatorLength / 256; // 256 pixels for zoom level 0
        let zoomLevel = Math.log2(zoomLevel0Scale / (miles * 1609.34)) - 1; // Convert miles to meters and calculate zoom level
        return Math.floor(zoomLevel);
    };
      
    // You can calculate this once and set it as the maxZoom
    const clusterMaxZoom = milesToZoom(30);
      

    return (
        <div>
            <SearchForm setCurrentLocation={setCurrentLocation} setIsLoading={setIsLoading} bounds={bounds} showSearch={handleSearch} onHideMap={(visible) => setVisible(visible)} isSmall={isSearchBarSmall} setIsSearchBarSmall={setIsSearchBarSmall} zoom={zoom} currentLocation={currentLocation} isReleaseMouseEvent={isReleaseMouseEvent} movementCounter={movementCounter} />
            <div className={`${visible ? '' : 'fade-out'} map-wrap`}>
                <LeftColumn infoWindowData={infoWindowData} isOpen={isOpen}  zoom={zoom} isLoading={isLoading} isVisible={isLeftColumnVisible} items={searchResults} setZoom={setZoom} />
                <div
                    className='googleColumn'
                    style={{
                        width: "50%",
                        transition: "width 0.5s ease",
                        position: 'relative',
                    }}
                    onWheel={(e) => {
                        setReleaseMouseEvent(true)
                        setMovementCounter(0)
                    }}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={currentLocation || center}
                        zoom={zoom}
                        onLoad={onMapLoad}
                        onClick={() => setIsOpen(false)}
                        options={mapOptions}
                        onMouseUp={(e) => setReleaseMouseEvent(true)}
                        onMouseDown={(e) => setMovementCounter(0)}
                        onZoomChanged={(e) => setMovementCounter(0)}
                    >
                        {currentLocation && (
                            <Marker
                                position={currentLocation}
                                icon={{
                                    url: require('../../assets/images/blue_marker.png').default,
                                    scaledSize: new window.google.maps.Size(30, 30)
                                }}
                            />
                        )}
                        <MarkerClusterer maxZoom={clusterMaxZoom}>
                            {(clusterer) =>
                                searchResults.length > 0 &&
                                searchResults.map((result, index) => (
                                    <Marker
                                        key={index}
                                        position={{ lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) }}
                                        clusterer={clusterer}
                                        onClick={() => {
                                            handleMarkerClick(index, result);
                                        }}
                                    >
                                        {isOpen && infoWindowData?.id === index && (
                                            <InfoWindow
                                            onCloseClick={() => {
                                                setIsOpen(false);
                                            }}
                                            >
                                                <div className='map-tooltip_wrap'>
                                                    <Link to={`/group/` + infoWindowData.result.id}><h3 className='map-tooltip_name'>{infoWindowData.result.name}</h3></Link>
                                                    <p className='map-tooltip_address'>{infoWindowData.result?.address ? infoWindowData.result?.address + ', ' : ''}{infoWindowData.result?.city ? infoWindowData.result?.city + ', ' : ''}{infoWindowData.result?.state ? infoWindowData.result?.state + ', ' : ''}{infoWindowData.result?.zip}</p>
                                                    <Link to={`/group/` + infoWindowData.result.id}><span className="map-tooltip_btn">View</span></Link>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </Marker>
                                ))
                            }
                        </MarkerClusterer>
                    </GoogleMap>
                </div>
            </div>
        </div>
    );
}

export default GroupMap