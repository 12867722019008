import React, { useEffect, useState } from 'react'
import ProfileInfo from '../components/ProfileInfo/ProfileInfo'
import { Spin, notification } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import {
    getProfile
} from '../store/profile/actions'

const Profile = (props) => {

    const dispatch = useDispatch()
    const location = useLocation();
    const { isEdit } = location.state || {};
    const user = useSelector((state) => state.profile.user.data)

    useEffect(() => {
        if (user)
            document.title = `Profile: ${user?.firstname} ${user?.lastname}`
        else
            document.title = `Profile`
    }, [user])

    useEffect(() => {
        if(!user)
            props.getProfile()
    }, [])

    useEffect(() => {
        if(isEdit == 'success') {
            NotificationManager.success("All changes have been successfully saved.", "Notification", 5000)
        }
    }, [isEdit])

    return (
        <Spin spinning={props.user.loading} size='large'>
            <div className='flex h-screen md-flex-wrap'>
                <ProfileInfo />
            </div>
        </Spin>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.profile.user,
    }
}

const mapDispachToProps = (dispatch) => ({
    getProfile: () => dispatch(getProfile()),
})

export default connect(mapStateToProps, mapDispachToProps)(Profile)