import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useUserSignOut, user, usePinSideBar } from '@oneblinktech-org/helios-base'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import {
    getProfile
  } from '../../store/profile/actions'
  
const Navbar = (props) => {
    const dispatch = useDispatch()
    const itc_user = useSelector((state) => state.profile.user.data)
    const userSignOut = useUserSignOut();
    const pinSidebar = usePinSideBar(true);

    useEffect(() => {
      dispatch(getProfile())
    }, [])
  
    useEffect(() => {
        if(user?.data?.id) {
            dispatch({type: "SET_PROFILE", payload: user.data})
          }
    }, [user?.data?.id])
    
    return (
        <div>
            <div className="hel-top-navbar d-flex flex-row align-items-center px-3 px-md-4">
                <div className='mobile'>
                    {
                        itc_user?.id &&
                        <>
                            <div className="menu-pin mobile_open" onClick={() => pinSidebar()}>
                                <FontAwesomeIcon icon={faBars} />
                            </div>
                            <div className="menu-pin mobile_close" onClick={() => pinSidebar()}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </>
                    }
                </div>
                <div className="d-flex flex-row align-items-center">
                        <Link to="/">
                        <img className="trustegrity_img" src={window.location.href.includes('highachievers') ? require('../../assets/images/High-Achievers.jpg').default : require('../../assets/images/trustegrity.png').default} alt="" />
                        </Link>
                </div>
                <div className="d-flex flex-row align-items-center ml-auto ml-md-0">
                    <Link to="/">
                        <button type="button" className='mr-2 mr-md-0 find_btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.34226 6.89866C7.92585 6.20466 8.28863 5.32138 8.28863 4.34346C8.28863 2.18258 6.52207 0.416016 4.34541 0.416016C2.16876 0.416016 0.417969 2.18258 0.417969 4.34346C0.417969 6.50434 2.18453 8.2709 4.34541 8.2709C5.32333 8.2709 6.20661 7.9239 6.90062 7.32453L8.96362 9.45583C9.08474 9.58096 9.28489 9.58259 9.40804 9.45945C9.52866 9.33882 9.52992 9.14364 9.41087 9.02146L7.34226 6.89866ZM4.34541 7.65576C2.53153 7.65576 1.04888 6.17311 1.04888 4.34346C1.04888 2.51381 2.53153 1.04693 4.34541 1.04693C6.15929 1.04693 7.64194 2.52958 7.64194 4.34346C7.64194 6.15734 6.17507 7.65576 4.34541 7.65576Z" fill="white"/></svg>
                            <span>Find a Group</span>
                        </button>
                    </Link>
                </div>
                {
                    itc_user?.id ?
                    <div className='log-out' onClick={() => {dispatch({type: "SET_PROFILE"}); userSignOut()}}>
                        <img src={require('../../assets/images/logout.svg').default} />
                        <span className="profile-link">Log out</span>
                    </div>
                    :
                    <Link to="/login" className='log-in '>
                        <img src={require('../../assets/images/logout.svg').default} />
                        <span className="profile-link">Log In</span>
                    </Link>
                }
                {/* <div className="col-2 navbar justify-content-end navbar-expand-lg navbar-light p-0 mb-0">
                    <ul className="navbar-list navbar-nav">
                        <li className="px-2 px-xl-3">
                            <a href="#">
                                <img src={require('../../assets/images/header/notification-on.svg').default} alt="" />
                            </a>
                        </li>
                        <li className="px-2 px-xl-3">
                            <a href="#">
                                <img src={require('../../assets/images/header/message.svg').default} alt="" />
                            </a>
                        </li>
                        <li className="px-2 px-xl-3">
                            <a href="#">
                                <img src={require('../../assets/images/header/board.svg').default} alt="" />
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    )
}

export default Navbar;