import React from 'react'
import { Link } from 'react-router-dom'

function ThankYou({ closeThankYou, reset, groupId, alert }) {
    return (
        <div className='thank-you-container'>
            {
                alert ?
                <span className='alert_text'>Your changes have been saved!</span>
                :
                <>
                    <div class="thank-you-label">THANK YOU!</div>
                    <div class="thank-you-for-container">
                        <span class="thank-you-for-container1">
                            <p class="thank-you-for">Thank you for the referral card!</p>
                            <p class="thank-you-for">Your support means a lot.</p>
                        </span>
                    </div>
                    <div class="frame-parent6">
                        <Link class="send-new-button" to={{ pathname: "/pastReferral", search: groupId ? `?groupId=${groupId}` : '', }} >Past Referrals</Link>
                        <button class="send-connection-button" onClick={() => {
                            reset()
                            closeThankYou()
                        }} >Send Another Referral</button>
                    </div>

                    { groupId !== null && groupId !== undefined && groupId !== '' && (
                        <Link className='return-group' to={`/group/` + groupId} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4438 9.81382H4.15925L8.14915 13.6029C8.35023 13.7938 8.46321 14.0527 8.46321 14.3228C8.46321 14.5928 8.35023 14.8518 8.14915 15.0427C7.94816 15.2336 7.67556 15.3409 7.3913 15.3409C7.10705 15.3409 6.83444 15.2336 6.63346 15.0427L0.813686 9.51517C0.395437 9.11733 0.395437 8.47291 0.813686 8.07507L6.63346 2.5482C6.84284 2.34932 7.11678 2.25 7.39142 2.25C7.66607 2.25 7.94048 2.3491 8.14939 2.54797C8.35047 2.73888 8.46344 2.99786 8.46344 3.26791C8.46344 3.53795 8.35047 3.79693 8.14939 3.98784L4.15925 7.77733H17.4438C18.0297 7.78562 18.5 8.23907 18.5 8.79557C18.5 9.35208 18.0297 9.80552 17.4438 9.81382Z" />
                            </svg>
                            Back to Group
                        </Link>
                    )}
                </>
            }
        </div>
    )
}

export default ThankYou