import React, { useEffect, useState } from 'react'
import GroupMap from '../components/map/GroupMap'
import { Select } from 'antd';
import { createApiClient } from '@oneblinktech-org/helios-base';

const apiUrl = process.env.REACT_APP_API_URL;
const { get } = createApiClient({ apiUrl });

const GroupFinder = () => {

    const [franchiseOpts, setFranchiseOpts] = useState()

    useEffect(async () => {
        document.title = 'Find a Group'
        try {
            const result = await get('/ithaca/franchises')
            if(result?.franchises.length > 0) {
                const transformed = result?.franchises.map(franchise => ({
                    value: franchise.id.toString(),
                    label: franchise.name
                }))
                setFranchiseOpts(transformed)
            }
        } catch (error) {
            
        }
    }, []);

    const handleChange = (value) => {
    };

    return (
        <div className="finder-board">
            {/* <div className="d-flex flex-column flex-md-row justify-content-start align-items-start align-items-md-center pb-3">
                <h5 className="title">Find A Group</h5>
                <Select
                    placeholder="Choose Franchise"
                    style={{
                        width: 200,
                        fontSize: '0.625rem',
                        color: '#39424E',
                    }}
                    className='group_finder'
                    dropdownStyle={{
                        backgroundColor: 'white',
                    }}
                    suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 .923 9.09 0 5 4.154.91 0 0 .923 5 6l5-5.077Z" fill="#39424E"/></svg>}
                    dropdownMatchSelectWidth={false}
                    onChange={handleChange}
                    options={franchiseOpts}
                />
            </div> */}
            <GroupMap />
        </div>
    )
}

export default GroupFinder