import Home from '../../assets/images/home.svg';
import Referrals from "../../assets/images/referrals.svg";
import InviteGuest from "../../assets/images/plus.svg";
import Connection from "../../assets/images/connection.svg";

export const transformMenu = (data) => {
    return data?.map(group => {
      return {
        title: group.name,
        name: group.name.toLowerCase().replace(/\s/g, '_'),
        is_heading: false,
        is_active: false,
        class_name: "",
        is_icon_class: true,
        is_accordion: true,
        children: [
          {
            title: "Home",
            name: "home",
            is_heading: false,
            is_active: false,
            class_name: "",
            icon: Home,
            link: "/group/" + group.id,
            is_icon_class: true,
          },
          {
            title: "Send Referral",
            name: "send_referral",
            is_heading: false,
            is_active: false,
            class_name: "",
            icon: Referrals,
            link: "/sendReferral?groupId=" + group.id,
            is_icon_class: true,
          },
          {
            title: "Invite Guest",
            name: "invite_guest",
            is_heading: false,
            is_active: false,
            class_name: "",
            icon: InviteGuest,
            link: `/#/invite-guest/${group?.legacy_group_id}`,
            is_icon_class: true,
          },
        ]
      };
    });
};