import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MoreAction from '../MoreActions/MoreAction';
import PortalPopup from '../PortalPopup/PortalPopup';
import ShowAll from '../ShowAll/ShowAll';
import WhyPopup from '../WhyPopup/WhyPopup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../helpers/dateFormat';
import { createApiClient } from '@oneblinktech-org/helios-base';
import SocialIcons from '../SocialIcons';

const ProfileInfo = () => {
    const [isMyWhyOpen, setMyWhyOpen] = useState(false);
    const [isShowAllOPen, setShowAllOpen] = useState(false);
    const [isMoreActionOpen, setMoreActionOpen] = useState(false);
    const [isPublicView, setPublicView] = useState(false)
    const [sponsorLinks, setSponsorLinks] = useState([])

    const apiUrl = process.env.REACT_APP_API_URL;
    const { get } = createApiClient({ apiUrl });

    const getMainDomain = (hostname) => {
        const parts = hostname.split('.')
        return parts.slice(-2).join('.')
    }
    const mainDomain = getMainDomain(window.location.hostname)
    const adminDomain = `admin.${mainDomain}`

    const user = useSelector((state) => state.profile.user.data)
    const history = useHistory();
    var pattern = /^((http|https|ftp):\/\/)/;

    useEffect(() => {
        if (user?.user_setting?.legacy_user_id)
            getSponserLinks()
    }, [user?.user_setting?.legacy_user_id])

    async function getSponserLinks() {
      try {
        const result = await get(
          `https://${adminDomain}/my2/user-sponsor-links/${user?.user_setting?.legacy_user_id}`)
        if (result)
          setSponsorLinks(result?.sponsorLinks)
      } catch (error) {
        console.log('error', error);
      }
    }

    // const openWhyPopup = useCallback(() => {
    //     setMyWhyOpen(!isMyWhyOpen);
    // }, []);

    const closeWhyPopup = useCallback(() => {
        setMyWhyOpen(false);
    }, []);

    // const openShowAll = useCallback(() => {
    //     setShowAllOpen(true);
    // }, []);

    const closeShowAll = useCallback(() => {
        setShowAllOpen(false);
    }, []);

    const openMoreAction = useCallback(() => {
        setMoreActionOpen(true);
    }, []);

    const closeMoreAction = useCallback(() => {
        setMoreActionOpen(false);
    }, []);

    const onFrameContainerClick = useCallback(() => {
        history.push("/EditProfile")
    }, []);

    if (user) {
      return (
            <>
                <div className="profile-info">
                    <div className="profile-inner-info">
                        {/* <div className="component-197-info"> */}
                        <div className="frame-parent-info edit-profilr md-w-full">

                            {/* <div className="iconarrowsnormalleft-parent">
                                        <img
                                            className="iconarrowsnormalleft"
                                            alt=""
                                            src={require('../../assets/images/left.svg').default}
                                        />
                                        <div className="back-to-dashboard">Back to Dashboard</div>
                                    </div> */}
                            <b className="profile-overview">Profile</b>
                            {isPublicView ? null : localStorage.getItem('h-access_token') && (
                                <div className="cta1" onClick={onFrameContainerClick}>Edit Profile</div>
                            )}
                            {/* <div className="cta-parent">
                                    <div className="cta">You have unsaved changes</div>
                                    <div className="component-195">
                                        <div className="cta1">Save</div>
                                    </div>
                                    <div className="cancel">
                                        <div className="cancel1">Cancel</div>
                                    </div>
                                </div> */}
                        </div>

                        <div className="info-content">
                            <div className="frame-group-info md-w-full">
                                {/* <div className="frame-child" /> */}
                                <div className="col">
                                    <div className="frame-container md-flex-wrap align-items-start">
                                        <div className='flex flex-row align-items-center justify-content-between w-auto info-header' >
                                            <div className="component-448-parent">
                                                <img
                                                    className="component-448-icon"
                                                    alt=""
                                                    src={user?.full_profile_image_url ? user?.full_profile_image_url : require('../../assets/images/user-avatar.png').default}
                                                />
                                                <div className="frame-div">
                                                    <div className="frame-parent1">
                                                        <div className="frame-wrapper">
                                                            <div className="frame-parent2">
                                                                <div className="frame-parent3">
                                                                    <div className="frame-wrapper">
                                                                        <div className="frame-parent4">
                                                                            <div className="profile-name-wrapper">
                                                                                <b className="profile-name">
                                                                                    {`${user?.firstname || ''} ${user?.lastname || ''}`}
                                                                                </b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/*<div className="username">{user?.email}</div>*/}
                                                                </div>
                                                                <div className="trustegrity-by-high">
                                                                    {user?.user_setting?.business_name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="frame-parent5">
                                                            {/*<div className="icons-parent">
                                                            <img
                                                                className="icons"
                                                                alt=""
                                                                src={require("../../assets/images/icons.svg").default}
                                                            />
                                                            <div className="text">Verified User</div>
                                                        </div>*/}
                                                            {
                                                                user?.member_since && (
                                                                    <div className="member-since-wrapper">
                                                                        <div className="member-since">
                                                                            Member since {dateFormat(user.member_since, 'USER_LOGIN_DATE_FORMAT')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="frame-parent6">
                                                        <SocialIcons
                                                            linkedin={user?.user_setting?.linkedin}
                                                            facebook={user?.user_setting?.facebook}
                                                            twitter={user?.user_setting?.twitter}
                                                            calendly={user?.user_setting?.calendly}
                                                            publicFields={user?.user_setting?.public_fields}
                                                            parentClassName='vector-icon'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {sponsorLinks && sponsorLinks?.length > 0 &&
                                            <div className='flex flex-column gap-2' >
                                                <b className='sponser-label'>{`My Sponsor Link${sponsorLinks?.length > 1 ? 's' : ''}`}</b>
                                                <div className='flex flex-column gap-1' >
                                                    {sponsorLinks?.map((link, index) => (
                                                        <div className='sponsor-item' key={index}>
                                                            {link?.name && link?.url && (
                                                                <>
                                                                    <span className='label'>
                                                                      {link?.name}: <span className='value'>{link?.url}</span>
                                                                    </span>
                                                                    <div onClick={() => navigator.clipboard.writeText(link?.url)}>
                                                                        <img
                                                                            className='copy-img'
                                                                            src={require('../../assets/images/copy-solid.svg').default}
                                                                            alt="Copy icon"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {/* {isPublicView ? null : localStorage.getItem('h-access_token') && (
                                            <div className="frame-parent7">
                                                <div className="frame-parent8" onClick={() => setPublicView(true)} >
                                                    <div className="vector-container">
                                                        <img
                                                            className="vector-icon1"
                                                            alt=""
                                                            src={require("../../assets/images/public-view.svg").default}
                                                        />
                                                    </div>
                                                    <div className="cta-wrapper">
                                                        <div className="cta1">Public View</div>
                                                    </div>
                                                </div>
                                                <div className="frame-parent9">
                                                    <div className="profile-name-wrapper">
                                                        <div className="vector-frame">
                                                            <img
                                                                className="vector-icon1"
                                                                alt=""
                                                                src={require("../../assets/images/share.svg").default}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="cta-wrapper">
                                                        <div className="cta1">Share Link</div>
                                                    </div>
                                                </div>
                                                <div className="frame-parent10">
                                                    <div className="vector-wrapper1">
                                                        <img
                                                            className="icon-color1"
                                                            alt=""
                                                            src={require("../../assets/images/impersonate.svg").default}
                                                        />
                                                    </div>
                                                    <div className="cta-wrapper">
                                                        <div className="cta1">Impersonate</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        } */}
                                    </div>
                                    {isPublicView ? null :
                                        localStorage.getItem('h-access_token') && (
                                            <div className="my-why md-flex-wrap md-w-full">
                                                <div className="text-parent relative md-w-full">
                                                    <b className="profile-name">My Why: {`${user?.user_setting?.my_why}`}</b>
                                                    <div
                                                        className="sed-ut-perspiciatis">
                                                        {`${user?.user_setting?.my_why_description}`}
                                                    </div>
                                                </div>
                                                <div className="my-info md-w-full">
                                                    <div className="text-wrapper md-w-full">
                                                        <b className="profile-name">My Info</b>
                                                    </div>
                                                    <div className="frame-parent13 md-flex-wrap md-w-full">
                                                        <div className="frame-parent14">
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">Email</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <div className="placeholder-profile">
                                                                        {user?.email}
                                                                    </div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">phone</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <div className="placeholder-profile">{user?.main_phone}</div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">cell</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <div className="placeholder-profile">{user?.user_setting?.mobile_phone}</div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">url</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <a
                                                                        className="placeholder-profile"
                                                                        href={user?.user_setting?.website}
                                                                        target="_blank"
                                                                    >
                                                                        {user?.user_setting?.website}
                                                                    </a>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">Address</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <div className="placeholder-profile">
                                                                        {`${user?.address || ''}`}
                                                                        {user?.address2 && <div>{user.address2}</div>}
                                                                        <div>{`${user?.city || ''}, ${user?.state || ''} ${user?.zipcode || ''}`}</div>
                                                                    </div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="frame-parent14">
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">Business DESCRIPTION</b>
                                                                </div>
                                                                <div className="placeholder-parent">
                                                                    <div className="placeholder-profile">
                                                                        {user?.user_setting?.business_description}
                                                                    </div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">profession</b>
                                                                </div>
                                                                <div className="placeholder-parent4">
                                                                    <div className="placeholder-profile">{user?.user_setting?.profession}</div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                            <div className="frame-parent15">
                                                                <div className="frame-wrapper">
                                                                    <b className="name">Speciality</b>
                                                                </div>
                                                                <div className="placeholder-parent4">
                                                                    <div className="placeholder-profile">{user?.user_setting?.specialty}</div>
                                                                    <div className="frame-inner" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }
                                    <div className="my-group md-w-full">
                                        <div className="text-group md-w-full">
                                            <b className="profile-name">My Groups</b>
                                            {/*<div className="show-all" >
                                                <div onClick={() => setShowAllOpen(!isShowAllOPen)} >Show All</div>
                                                {isShowAllOPen && (
                                                    // <PortalPopup
                                                    //     overlayColor="rgba(113, 113, 113, 0.3)"
                                                    //     placement="Centered"
                                                    //     onOutsideClick={closeShowAll}
                                                    // >
                                                    <ShowAll onClose={closeShowAll} />
                                                    // </PortalPopup>
                                                )}

                                            </div>*/}
                                        </div>
                                        <div className="show-only-on-mobile my-group-mobile">
                                            {user?.groups?.map((item, index) => (
                                                <div class="row" key={index}>
                                                    <div><strong>{item.name}</strong></div>
                                                    <div>
                                                        Member since
                                                        {item.member_since_date && !item.cancelled_at && `Since ${dateFormat(item.member_since_date, 'USER_LOGIN_DATE_FORMAT')}`}
                                                        {item.member_since_date && item.cancelled_at && `${dateFormat(item.member_since_date, 'USER_LOGIN_DATE_FORMAT')} - ${dateFormat(item.cancelled_at, 'USER_LOGIN_DATE_FORMAT')}`}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="frame-parent25 w-full">
                                            <div className="table-header-cell-parent md-w-full table-header-cells w-full">
                                                <div className="table-header-cell">
                                                    <div className="table-header">
                                                        <b className="text4">GROUP NAME</b>
                                                        {/*<img
                                                            className="arrow-down-icon"
                                                            alt=""
                                                            src={require("../../assets/images/arrowdown.svg").default}
                                                        />*/}
                                                    </div>
                                                </div>
                                                <div className="table-header-cell table-header-cell1 justify-content-center">
                                                    <div className="cta-wrapper">
                                                        <b className="text4">STATUS</b>
                                                    </div>
                                                </div>
                                                <div className="table-header-cell table-header-cell2 justify-content-end">
                                                    <div className="cta-wrapper">
                                                        <b className="text4">MY MEMBERSHIP</b>
                                                    </div>
                                                </div>
                                                {/* <div className="table-header-cell table-header-cell3">
                                                    <div className="cta-wrapper">
                                                        <b className="text7">MEMBERS</b>
                                                    </div>
                                                </div> */}
                                                <div className="table-header-cell table-header-cell4" />
                                            </div>
                                            {user?.groups?.map((item, index) => {
                                                return (
                                                    <div className="profile-name-wrapper md-w-full table-cells">
                                                        <div className="table-cell">
                                                            {/* <img
                                                                className="table-cell-child"
                                                                alt=""
                                                                src={item?.full_profile_image_url ? item?.full_profile_image_url : require("../../assets/images/user-avatar.png").default}
                                                            /> */}
                                                            <div className="frame-wrapper">
                                                                <div className="text8">{item.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="table-cell table-cell1 justify-content-center">
                                                            <div className="table-cell-item" />
                                                            <div className="frame-wrapper">
                                                                <div className="text9" title={item.status}>{item.status}</div>
                                                            </div>
                                                        </div>
                                                        <div className="table-cell table-cell2 justify-content-end">
                                                            <div className="frame-wrapper">
                                                                <div className="text10">
                                                                    <p className="pending">
                                                                        {item.member_since_date && !item.cancelled_at && 'Since '}
                                                                        {item.member_since_date && dateFormat(item.member_since_date, 'USER_LOGIN_DATE_FORMAT')}
                                                                        {item.member_since_date && item.cancelled_at && ' - '}
                                                                        {item.cancelled_at && dateFormat(item.cancelled_at, 'USER_LOGIN_DATE_FORMAT')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {<div className="table-cell table-cell3">
                                                            {/*<div className="avatar-group">
                                                                <div className="profile-name-wrapper md-w-full">
                                                                    <img
                                                                        className="avatar-icon"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon1"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon1"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon1"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon1"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon5"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon5"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon5"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon5"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <img
                                                                        className="avatar-icon5"
                                                                        alt=""
                                                                        src={require("../../assets/images/user-avatar.png").default}
                                                                    />
                                                                    <div className="avatar">
                                                                        <div className="text11">+5</div>
                                                                    </div>
                                                                </div>
                                                            </div>*/}
                                                        </div>}
                                                        {/*isMoreActionOpen && (
                                                            // <PortalPopup
                                                            //     overlayColor="rgba(113, 113, 113, 0.3)"
                                                            //     placement="Centered"
                                                            //     onOutsideClick={closeMoreAction}
                                                            // >
                                                            <MoreAction onClose={closeMoreAction} />
                                                            // </PortalPopup>
                                                        )}
                                                        <div className="table-cell table-cell4">
                                                            {isPublicView ? null : localStorage.getItem('h-access_token') && (
                                                                <div className="dropdown" onClick={() => setMoreActionOpen(!isMoreActionOpen)}>
                                                                    <img
                                                                        className="more-vertical-icon"
                                                                        alt=""
                                                                        src={require("../../assets/images/more-vertical.svg").default}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>*/}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="scrollbarverical-scrollbar">
                                            <div className="scrollbarverical-scrollbar-child" />
                                            <div className="scrollbarverical-scrollbar-item" />
                                        </div> */}
                            </div>
                        </div>

                        {/* </div> */}
                    </div>
                </div>
                {/* {isMoreActionOpen && (
                    <PortalPopup
                        overlayColor="rgba(113, 113, 113, 0.3)"
                        placement="Centered"
                        onOutsideClick={closeMoreAction}
                    >
                        <MoreAction onClose={closeMoreAction} />
                    </PortalPopup>
                )} */}

                {/* {isShowAllOPen && (
                    <PortalPopup
                        overlayColor="rgba(113, 113, 113, 0.3)"
                        placement="Centered"
                        onOutsideClick={closeShowAll}
                    >
                        <ShowAll onClose={closeShowAll} />
                    </PortalPopup>
                )} */}


            </>
        )
    }

    return null
}

export default ProfileInfo

const Groups = [{
    name: 'Alpharetta GA',
    status: 'Active',
    start: 'May 22, 2022 -',
    end: 'Present'
},
{
    name: 'Blount Business Connectors',
    status: 'Canceled',
    start: 'Jun 15, 2021 -',
    end: 'Jun 14, 2022'
},
{
    name: 'Jacksonville Veterans Group',
    status: 'Rejected',
    start: '-',
    end: ''
},
{
    name: 'GY Catalyst Group',
    status: 'Pending application',
    start: 'Pending',
    end: '2 days ago'
}]