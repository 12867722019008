import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getActiveLink} from '../../helpers/pluginsInit';
import { useSelector } from 'react-redux';
import { usePinSideBar } from '@oneblinktech-org/helios-base'

const accordionStyle = {
    borderTop: '1px solid #C7D3E6',
    borderBottom: '1px solid #C7D3E6',
    background: '#FAFAFB'
}

const MenuList = (props) => {

    const [menuState, setMenuState] = useState([]);
    const user = useSelector((state) => state.profile.user.data)
    const pinSidebar = usePinSideBar(true);

    const isMobile = () => {
        return window.matchMedia('(max-width: 768px)').matches;
    };

    useEffect(() => {
        const storedState = localStorage.getItem('menuState');
        if (storedState) {
            const parsedState = JSON.parse(storedState);
            if(parsedState.id = user?.id) {
                setMenuState(parsedState?.menu || []);
            }
        }
    }, [props.items]);

    const handleLinkClick = () => {
        const mobile = isMobile();
        if (mobile) {
            pinSidebar()
        } else {
            console.log('Device is not mobile.');
        }
    };


    const onToggleMenu = (item) => {
        if (item?.is_accordion) {
            const updatedMenus = menuState.includes(item.name)
            ? menuState.filter(name => name != item.name)
            : [...menuState, item.name];
            setMenuState(updatedMenus);
            localStorage.setItem("menuState", JSON.stringify({id: user.id, menu: updatedMenus}));
        }
    }

    return props.items.map((subItem, subIndex) => {
        return (
            <>
                {
                    subItem.label ?
                    <span className='label mt-3'>{subItem.label}</span>
                    :
                    <li key={subIndex + "submenu"}
                        style={subItem.is_accordion ? accordionStyle : {} }
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggleMenu(subItem);
                        }}
                        className={
                            (
                                subItem.is_heading
                                    ? "hel-menu-title"
                                    : ""
                            ) +
                            ` show ${subItem.is_accordion ? 'accordion' : ''} ${menuState.includes(subItem.name) ? 'listOpen' : 'listClose'}`
                        }>
                        {
                            (subItem.is_heading)
                                ? (
                                    <div>
                                        {
                                            <span className={subItem.is_accordion ? 'accordion' : ''}>{subItem.title}</span>
                                        }
                                    </div>
                                    )
                                    : subItem?.name === "invite_guest" ?
                                        <a href={subItem?.link} 
                                            onClick={() => handleLinkClick()}
                                            className={`${subItem.is_accordion ? 'title_accordion' : ''} hel-waves-effect  ` + getActiveLink(subItem) && subItem.children ? 'active' : getActiveLink(subItem) ? 'active' : ''}>
                                        {
                                            subItem.icon &&
                                            <img alt="" src={subItem.icon} className="menu-icon" />
                                        }
                                        <div className='d-flex flex-row align-items-center justify-content-between w-full'>
                                            <span className={subItem.is_accordion ? 'accordion' : ''}>{subItem.title}</span>
                                            {subItem.unreadCount > 0 && (
                                                <div className={'unread-count'}>
                                                    <span>{`${subItem.unreadCount > 99 ? '99+' : subItem.unreadCount}`}</span>
                                                </div>
                                            )}
                                            {
                                                subItem.is_accordion &&
                                                <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.577 10 .5 5.5 5.346 1 .5 0 1.577 5.5 7.5 11 1.577Z" fill="#39424E"/></svg>
                                            }
                                        </div>
                                            </a>
                                        : (
                                    (subItem.link !== undefined) ? (
                                            <Link to={subItem.link}
                                                    onClick={() => handleLinkClick()}
                                                    className={`${subItem.is_accordion ? 'title_accordion' : ''} hel-waves-effect  ` + getActiveLink(subItem) && subItem.children ? 'active' : getActiveLink(subItem) ? 'active' : ''}>
                                                {
                                                    subItem.icon &&
                                                    <img alt="" src={subItem.icon} className="menu-icon" />
                                                }
                                                <div className='d-flex flex-row align-items-center justify-content-between w-full'>
                                                    <span className={subItem.is_accordion ? 'accordion' : ''}>{subItem.title}</span>
                                                    {subItem.unreadCount > 0 && (
                                                        <div className={'unread-count'}>
                                                            <span>{`${subItem.unreadCount > 99 ? '99+' : subItem.unreadCount}`}</span>
                                                        </div>
                                                    )}
                                                    {
                                                        subItem.is_accordion &&
                                                        <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.577 10 .5 5.5 5.346 1 .5 0 1.577 5.5 7.5 11 1.577Z" fill="#39424E"/></svg>
                                                    }
                                                </div>
                                            </Link>
                                        )
                                        :
                                        (
                                            <a className={`${subItem.is_accordion ? 'title_accordion' : ''} hel-waves-effect  ` + subItem.name }>
                                                {
                                                    subItem.icon && 
                                                    <img alt="" src={subItem.icon} className="menu-icon" />
                                                }
                                                <div>
                                                    <div className='d-flex flex-row align-items-center justify-content-between w-full'>
                                                        <span className={subItem.is_accordion ? 'accordion' : ''}>{subItem.title}</span>
                                                        {
                                                            subItem.is_accordion &&
                                                            <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.577 10 .5 5.5 5.346 1 .5 0 1.577 5.5 7.5 11 1.577Z" fill="#39424E"/></svg>
                                                        }
                                                    </div>

                                                    {subItem.count &&
                                                        <span className="count-section" style={{backgroundColor: subItem.count_color ? subItem.count_color : ""}}>{subItem.count}</span>
                                                    }
                                                </div>
                                            </a>
                                        )
                                )}
                        {(subItem.children !== undefined && subItem.children.length > 0) && (
                            <ul className={"hel-submenu collapse " + subItem.className ? subItem.className : ''} id={subItem.name} data-parent={subItem.class_name !== undefined && subItem.class_name !== '' ? '' : '#hel-sidebar-toggle'}>
                                <MenuList items={subItem.children}
                                        className={"hel-submenu collapse " + subItem.className ? subItem.className : ''}
                                        id={subItem.name}
                                        dataParent={subItem.class_name !== undefined && subItem.class_name !== '' ? '' : '#hel-sidebar-toggle'}/>
                            </ul>
                        )}
                    </li>
                }
            </>
        )
    }
)
}
export default MenuList;