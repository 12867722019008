import React from 'react'
import { useLocation } from 'react-router-dom'

const JoinGroup = () => {
  const location = useLocation()

  const isValidUrl = (url) => {
    try {
      new URL(url)
      return true
    } catch (e) {
      return false
    }
  }
  const token = localStorage.getItem('h-access_token')
  const joinGroup = location.state?.joinGroupLink + `?token=${token}`

  return (
    <div>
      {isValidUrl(joinGroup) ? (
        <iframe src={joinGroup} style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                title="Join Group"/>
      ) : (
        <div>No url selected</div>
      )}
    </div>
  )
}

export default JoinGroup
