import moment from 'moment-timezone';

const timezoneShortform = {
    "America/New_York": "ET",
    "America/Chicago": "CT",
    "America/Denver": "MT",
    "America/Phoenix": "MST",
    "America/Los_Angeles": "PT",
    "America/Anchorage": "AKT",
    "America/Adak": "HST",
    "Pacific/Honolulu": "HST"
};

function getShortform(timezone) {
    return timezoneShortform[timezone] || timezone;
}

const format = { 
    TXT_DATE : "MMM DD, h:mm A",
    TXT_YEAR_DATE : "MMM DD, Y h:mm A", 
    LIST_DATE : "DD-MM-YYYY hh:mm", 
    DUR_DATE: "MMM DD", 
    USER_LOGIN_DATE_FORMAT: "MMM D, YYYY", 
    USER_LOGIN_DATE_TIME_FORMAT: "MMM D, YYYY - h:mm A", 
    EST_LONGDATE_FORMAT: "MMMM D, YYYY [at] h:mmA",
    EST_TIME_FORMAT: "hh:mm A"
};

export const dateFormat = (date, type, timezone = "America/New_York") => {
    let formattedDate = moment(date).tz(timezone).format(format[type]);
    
    // If the format type requires a timezone shortform, append it.
    if (['TXT_DATE', 'TXT_YEAR_DATE', 'USER_LOGIN_DATE_TIME_FORMAT', 'EST_LONGDATE_FORMAT'].includes(type)) {
        formattedDate += ` ${getShortform(timezone)}`;
    }
    
    return formattedDate;
}
